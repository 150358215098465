<template>
  <v-data-iterator
    :loading="!topPounders"
    mobile-breakpoint="0"
    disable-sort
    loading-text="Loading...Please Wait"
    :items="topPounders"
    :page="page"
    hide-default-footer
    class="link"
  >
    <template v-slot:default="props">
      <v-card
        shaped
        class="charcoal my-2 mx-0 pa-2 link "
        flat
        v-for="item in props.items"
        :key="item.id"
      >
        <v-card-actions class="py-0 pl-0">
          <v-icon v-if="parseInt(item.rank) <= 3" :color="starColor(item.rank)">
            mdi-star
          </v-icon>
          <v-icon x-small v-else color="charcoal lighten-1" class=" mr-2">
            mdi-circle
          </v-icon>
          <span
            :class="textSizeSmall + ' mr-2 ' + starColor(item.rank) + '--text'"
            >{{ item.rank }}
          </span>
          <span :class="textSizeSmall + ' pl-0t'">
            <BaseUserProfile :item="item" :key="item.id" />
          </span>
          <v-spacer />
          <span class="caption silver--text pl-10">
            Age: {{ item.age }}, {{ item.gender }}
          </span>
        </v-card-actions>
        <v-card-actions class="py-0 px-0">
          <template v-if="normalized">
            <BaseTag
              v-if="item.total_metric_k > 0"
              icon="mdi-account"
              :label="
                'SELFS ' + Math.floor(item.total_self_lifts).toLocaleString()
              "
              tagsize="medium"
              color="charcoal paper--text"
            />
          </template>
          <template v-if="!normalized">
            <BaseTag
              v-if="item.total_imperial_k > 0"
              icon="mdi-weight"
              :label="
                'LBS ' +
                  Math.floor(item.total_imperial_k).toLocaleString() +
                  ' K'
              "
              tagsize="medium"
              color="charcoal paper--text"
            />

            <v-spacer />
            <BaseTag
              v-if="item.total_metric_k > 0"
              icon="mdi-weight"
              :label="
                'KG ' + Math.floor(item.total_metric_k).toLocaleString() + ' K'
              "
              tagsize="medium"
              color="charcoal paper--text"
            />
          </template>
        </v-card-actions>
      </v-card>
    </template>
    <template v-slot:footer>
      <v-card-actions class="px-2 mt-4 pb-0">
        <span class="silver--text caption">Per page</span>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn dark text color="silver" class="ml-2" v-on="on">
              {{ itemsPerPage }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(number, index) in itemsPerPageArray"
              :key="index"
              @click="updateItemsPerPage(number)"
            >
              <v-list-item-title>{{ number }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer />
        <span class="caption silver--text py-2 px-0">
          Page {{ page }} of {{ numberOfPages }}
        </span>
        <v-btn fab text color="silver" small @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          fab
          text
          color="silver"
          small
          class="ml-0  mr-n4"
          @click="nextPage"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </template>
    <template v-slot:no-data>
      <v-card-text class="py-10 my-10 body-1 paper--text text--darken-1">
        Sorry, we found no data for this interval.
      </v-card-text>
    </template>
    <template v-slot:no-results>
      <v-card-text class="py-10 my-10 body-1 paper--text text--darken-1">
        Sorry, we found no data matching this criteria.
      </v-card-text>
    </template>
  </v-data-iterator>
</template>
<script>
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    normalized: {
      type: Boolean,
      default: false
    },
    sex: {
      type: String,
      default: '%'
    },
    topPounders: {
      type: Array
    }
  },
  data() {
    return {
      itemsPerPageArray: [100, 200, 500],
      itemsPerPage: 100,
      page: 1,
      intervals: [
        { text: 'Day', value: 'day' },
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
        { text: 'Year', value: 'year' },
        { text: 'Rolling 30', value: 'rolling_30' }
      ]
    }
  },
  computed: {
    itemsCount() {
      return this.topPounders.length
    },
    numberOfPages() {
      return Math.ceil(this.topPounders.length / this.itemsPerPage)
    }
  },
  methods: {
    starColor(rank) {
      if (rank == 1) return 'gold'
      else if (rank == 2) return 'silver'
      else if (rank == 3) return 'bronze'
      else return 'silver'
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    viewProfile(value) {
      this.$router.push('/profile/' + value.profile_id)
    }
  }
}
</script>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
